import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPikaichiMenuGetMenu = gql`
    query EditPikaichiMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const EditPikaichiMenuGetPikaichiMenus = gql`
    query EditPikaichiMenuGetPikaichiMenus($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuId
    pikaichiMenuName
    pikaichiMenuCd
  }
}
    `;
export const EditPikaichiMenuUpdatePikaichiMenu = gql`
    mutation EditPikaichiMenuUpdatePikaichiMenu($menuId: uuid!, $_menuId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuMenu_one(
    object: {pikaichiMenuId: $pikaichiMenuId, menuId: $menuId, _menuId: $_menuId}
    on_conflict: {constraint: pikaichiMenuMenu_pikaichiMenuId_menuId_key, update_columns: []}
  ) {
    pikaichiMenuId
    menuId
    _menuId
  }
}
    `;
export type EditPikaichiMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditPikaichiMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, pikaichiMenuMenus: Array<(
      { __typename?: 'pikaichiMenuMenu' }
      & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId'>
      & { menuId: Types.PikaichiMenuMenu['_menuId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type EditPikaichiMenuGetPikaichiMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPikaichiMenuGetPikaichiMenusQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenu: Array<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuName' | 'pikaichiMenuCd'>
  )> }
);

export type EditPikaichiMenuUpdatePikaichiMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['uuid'];
  _menuId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
}>;


export type EditPikaichiMenuUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuMenu' }
    & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId' | 'menuId' | '_menuId'>
  )> }
);


export const EditPikaichiMenuGetMenuDocument = gql`
    query EditPikaichiMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __useEditPikaichiMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditPikaichiMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditPikaichiMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiMenuGetMenuQuery, EditPikaichiMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiMenuGetMenuQuery, EditPikaichiMenuGetMenuQueryVariables>(EditPikaichiMenuGetMenuDocument, options);
      }
export function useEditPikaichiMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiMenuGetMenuQuery, EditPikaichiMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiMenuGetMenuQuery, EditPikaichiMenuGetMenuQueryVariables>(EditPikaichiMenuGetMenuDocument, options);
        }
export type EditPikaichiMenuGetMenuQueryHookResult = ReturnType<typeof useEditPikaichiMenuGetMenuQuery>;
export type EditPikaichiMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditPikaichiMenuGetMenuLazyQuery>;
export type EditPikaichiMenuGetMenuQueryResult = Apollo.QueryResult<EditPikaichiMenuGetMenuQuery, EditPikaichiMenuGetMenuQueryVariables>;
export const EditPikaichiMenuGetPikaichiMenusDocument = gql`
    query EditPikaichiMenuGetPikaichiMenus($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuId
    pikaichiMenuName
    pikaichiMenuCd
  }
}
    `;

/**
 * __useEditPikaichiMenuGetPikaichiMenusQuery__
 *
 * To run a query within a React component, call `useEditPikaichiMenuGetPikaichiMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiMenuGetPikaichiMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiMenuGetPikaichiMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPikaichiMenuGetPikaichiMenusQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiMenuGetPikaichiMenusQuery, EditPikaichiMenuGetPikaichiMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiMenuGetPikaichiMenusQuery, EditPikaichiMenuGetPikaichiMenusQueryVariables>(EditPikaichiMenuGetPikaichiMenusDocument, options);
      }
export function useEditPikaichiMenuGetPikaichiMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiMenuGetPikaichiMenusQuery, EditPikaichiMenuGetPikaichiMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiMenuGetPikaichiMenusQuery, EditPikaichiMenuGetPikaichiMenusQueryVariables>(EditPikaichiMenuGetPikaichiMenusDocument, options);
        }
export type EditPikaichiMenuGetPikaichiMenusQueryHookResult = ReturnType<typeof useEditPikaichiMenuGetPikaichiMenusQuery>;
export type EditPikaichiMenuGetPikaichiMenusLazyQueryHookResult = ReturnType<typeof useEditPikaichiMenuGetPikaichiMenusLazyQuery>;
export type EditPikaichiMenuGetPikaichiMenusQueryResult = Apollo.QueryResult<EditPikaichiMenuGetPikaichiMenusQuery, EditPikaichiMenuGetPikaichiMenusQueryVariables>;
export const EditPikaichiMenuUpdatePikaichiMenuDocument = gql`
    mutation EditPikaichiMenuUpdatePikaichiMenu($menuId: uuid!, $_menuId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuMenu_one(
    object: {pikaichiMenuId: $pikaichiMenuId, menuId: $menuId, _menuId: $_menuId}
    on_conflict: {constraint: pikaichiMenuMenu_pikaichiMenuId_menuId_key, update_columns: []}
  ) {
    pikaichiMenuId
    menuId
    _menuId
  }
}
    `;
export type EditPikaichiMenuUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<EditPikaichiMenuUpdatePikaichiMenuMutation, EditPikaichiMenuUpdatePikaichiMenuMutationVariables>;

/**
 * __useEditPikaichiMenuUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `useEditPikaichiMenuUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiMenuUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPikaichiMenuUpdatePikaichiMenuMutation, { data, loading, error }] = useEditPikaichiMenuUpdatePikaichiMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      _menuId: // value for '_menuId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *   },
 * });
 */
export function useEditPikaichiMenuUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditPikaichiMenuUpdatePikaichiMenuMutation, EditPikaichiMenuUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPikaichiMenuUpdatePikaichiMenuMutation, EditPikaichiMenuUpdatePikaichiMenuMutationVariables>(EditPikaichiMenuUpdatePikaichiMenuDocument, options);
      }
export type EditPikaichiMenuUpdatePikaichiMenuMutationHookResult = ReturnType<typeof useEditPikaichiMenuUpdatePikaichiMenuMutation>;
export type EditPikaichiMenuUpdatePikaichiMenuMutationResult = Apollo.MutationResult<EditPikaichiMenuUpdatePikaichiMenuMutation>;
export type EditPikaichiMenuUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<EditPikaichiMenuUpdatePikaichiMenuMutation, EditPikaichiMenuUpdatePikaichiMenuMutationVariables>;