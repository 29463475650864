import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { PikaichiMenu } from "../../types";
import { EditPlanPikaichiPlanFormItem } from "../useEditPlanPikaichiPlanForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  pikaichiMenuId: string | undefined;
};

export const PikaichiMenuCdField = memo<Props>(({ pikaichiMenus, pikaichiMenuId, ...props }) => (
  <EditPlanPikaichiPlanFormItem
    label="メニューコード"
    name="pikaichiMenuCd"
    rules={[
      { required: true, pattern: /^[1-9]\d{0,7}$/, message: "8桁以内の数字で入力してください" },
      {
        validator: async (_, value) => {
          if (!value) return;

          const conflictingExistingPikaichiMenu = pikaichiMenus.find(
            (pikaichiMenu) =>
              pikaichiMenu.pikaichiMenuCd === value &&
              pikaichiMenu.pikaichiMenuId !== pikaichiMenuId,
          );

          if (conflictingExistingPikaichiMenu) {
            throw new Error(
              `既存のコードと競合しています ${conflictingExistingPikaichiMenu.pikaichiMenuName}`,
            );
          }
        },
      },
    ]}
    {...props}
  >
    <InputCode />
  </EditPlanPikaichiPlanFormItem>
));
