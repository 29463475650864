import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { PaginationProps } from "antd/lib";
import { convertToRanking } from "models/customerDashboard";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { RepeaterRanking } from "components/RepeaterRanking";
import { useCompany } from "hooks/useCompany";

import {
  useRankingVisitedCountsGetCustomerVisitedCountRankingCountQuery,
  useRankingVisitedCountsGetCustomerVisitedCountRankingQuery,
} from "./queries";

const DEFAULT_PAGE_SIZE = 10;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const RankingVisitedCounts = () => {
  const [company] = useCompany();
  const companyId = company?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);

  const onChangePage: PaginationProps["onChange"] = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = useCallback(
    (_: number, size: number) => {
      setCurrentPageSize(size);
    },
    [],
  );

  const {
    data: getCustomerVisitedCountRankingData,
    loading: getCustomerVisitedCountRankingDataLoading,
    error: getCustomerVisitedCountRankingDataError,
  } = useRankingVisitedCountsGetCustomerVisitedCountRankingQuery(
    companyId
      ? { variables: { input: { companyId, limit: currentPageSize, page: currentPage } } }
      : { skip: true },
  );

  const {
    data: getCustomerVisitedCountRankingCountData,
    loading: getCustomerVisitedCountRankingCountDataLoading,
    error: getCustomerVisitedCountRankingCountDataError,
  } = useRankingVisitedCountsGetCustomerVisitedCountRankingCountQuery(
    companyId ? { variables: { input: { companyId } } } : { skip: true },
  );

  const visitedRankingData = convertToRanking({
    customers: getCustomerVisitedCountRankingData?.customerVisitedCountRanking.customers,
    currentPage,
    pageSize: currentPageSize,
  });

  const totalRankingCount = useMemo(
    () =>
      getCustomerVisitedCountRankingCountData?.customerVisitedCountRankingCount.totalRankingCount ??
      0,
    [getCustomerVisitedCountRankingCountData],
  );

  return (
    <DashboardLayout title="リピート数ランキング">
      {getCustomerVisitedCountRankingDataError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <RepeaterRanking
        rankingData={visitedRankingData}
        loading={getCustomerVisitedCountRankingDataLoading}
        title="リピート数ランキング"
        valueUnit="回"
        pageSize={currentPageSize}
        currentPage={currentPage}
        onChangePage={onChangePage}
        onShowSizeChange={onShowSizeChange}
        totalRankingCount={totalRankingCount}
      />
    </DashboardLayout>
  );
};
