import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditWinboardPlanGetPlan = gql`
    query EditWinboardPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const EditWinboardPlanGetWinboardMenus = gql`
    query EditWinboardPlanGetWinboardMenus($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    id
    name
    code
  }
}
    `;
export const EditWinboardPlanUpdateWinboardMenu = gql`
    mutation EditWinboardPlanUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planId, _planId]}
  ) {
    id
  }
}
    `;
export type EditWinboardPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditWinboardPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type EditWinboardPlanGetWinboardMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditWinboardPlanGetWinboardMenusQuery = (
  { __typename?: 'query_root' }
  & { winboardMenu: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id' | 'name' | 'code'>
  )> }
);

export type EditWinboardPlanUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
}>;


export type EditWinboardPlanUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const EditWinboardPlanGetPlanDocument = gql`
    query EditWinboardPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useEditWinboardPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditWinboardPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWinboardPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditWinboardPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditWinboardPlanGetPlanQuery, EditWinboardPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditWinboardPlanGetPlanQuery, EditWinboardPlanGetPlanQueryVariables>(EditWinboardPlanGetPlanDocument, options);
      }
export function useEditWinboardPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditWinboardPlanGetPlanQuery, EditWinboardPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditWinboardPlanGetPlanQuery, EditWinboardPlanGetPlanQueryVariables>(EditWinboardPlanGetPlanDocument, options);
        }
export type EditWinboardPlanGetPlanQueryHookResult = ReturnType<typeof useEditWinboardPlanGetPlanQuery>;
export type EditWinboardPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditWinboardPlanGetPlanLazyQuery>;
export type EditWinboardPlanGetPlanQueryResult = Apollo.QueryResult<EditWinboardPlanGetPlanQuery, EditWinboardPlanGetPlanQueryVariables>;
export const EditWinboardPlanGetWinboardMenusDocument = gql`
    query EditWinboardPlanGetWinboardMenus($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    id
    name
    code
  }
}
    `;

/**
 * __useEditWinboardPlanGetWinboardMenusQuery__
 *
 * To run a query within a React component, call `useEditWinboardPlanGetWinboardMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardPlanGetWinboardMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWinboardPlanGetWinboardMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditWinboardPlanGetWinboardMenusQuery(baseOptions: Apollo.QueryHookOptions<EditWinboardPlanGetWinboardMenusQuery, EditWinboardPlanGetWinboardMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditWinboardPlanGetWinboardMenusQuery, EditWinboardPlanGetWinboardMenusQueryVariables>(EditWinboardPlanGetWinboardMenusDocument, options);
      }
export function useEditWinboardPlanGetWinboardMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditWinboardPlanGetWinboardMenusQuery, EditWinboardPlanGetWinboardMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditWinboardPlanGetWinboardMenusQuery, EditWinboardPlanGetWinboardMenusQueryVariables>(EditWinboardPlanGetWinboardMenusDocument, options);
        }
export type EditWinboardPlanGetWinboardMenusQueryHookResult = ReturnType<typeof useEditWinboardPlanGetWinboardMenusQuery>;
export type EditWinboardPlanGetWinboardMenusLazyQueryHookResult = ReturnType<typeof useEditWinboardPlanGetWinboardMenusLazyQuery>;
export type EditWinboardPlanGetWinboardMenusQueryResult = Apollo.QueryResult<EditWinboardPlanGetWinboardMenusQuery, EditWinboardPlanGetWinboardMenusQueryVariables>;
export const EditWinboardPlanUpdateWinboardMenuDocument = gql`
    mutation EditWinboardPlanUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planId, _planId]}
  ) {
    id
  }
}
    `;
export type EditWinboardPlanUpdateWinboardMenuMutationFn = Apollo.MutationFunction<EditWinboardPlanUpdateWinboardMenuMutation, EditWinboardPlanUpdateWinboardMenuMutationVariables>;

/**
 * __useEditWinboardPlanUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useEditWinboardPlanUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardPlanUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWinboardPlanUpdateWinboardMenuMutation, { data, loading, error }] = useEditWinboardPlanUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *   },
 * });
 */
export function useEditWinboardPlanUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditWinboardPlanUpdateWinboardMenuMutation, EditWinboardPlanUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWinboardPlanUpdateWinboardMenuMutation, EditWinboardPlanUpdateWinboardMenuMutationVariables>(EditWinboardPlanUpdateWinboardMenuDocument, options);
      }
export type EditWinboardPlanUpdateWinboardMenuMutationHookResult = ReturnType<typeof useEditWinboardPlanUpdateWinboardMenuMutation>;
export type EditWinboardPlanUpdateWinboardMenuMutationResult = Apollo.MutationResult<EditWinboardPlanUpdateWinboardMenuMutation>;
export type EditWinboardPlanUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<EditWinboardPlanUpdateWinboardMenuMutation, EditWinboardPlanUpdateWinboardMenuMutationVariables>;