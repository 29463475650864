import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { WinboardMenuInsertInput } from "types/graphql";

import { Plan } from "../types";

import { EditPlanChoiceWinboardMenuForm } from "./EditPlanChoiceWinboardMenuForm";
import {
  useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery,
  useWinboardPlanOptionsEditPlanChoiceModalGetWinboardMenusQuery,
  useWinboardPlanOptionsUpdateWinboardMenuMutation,
} from "./queries";

type Props = {
  plan: Plan;
  onCompleted: () => void;
  onCloseModal: () => void;
};

export const EditPlanChoiceModal = memo<Props>(({ onCloseModal, plan, onCompleted }) => {
  const { planChoiceId: id } = useParams<{ planChoiceId: string }>();
  const planChoiceId = Number(id);

  if (Number.isNaN(planChoiceId)) {
    onCloseModal();
  }

  const [company] = useCompany();

  const {
    data: getPlanChoiceData,
    loading,
    error,
  } = useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery({
    variables: { planId: plan.planId, planChoiceId },
  });
  const planChoice = getPlanChoiceData?.planChoice?.[0];
  const winboardMenu = planChoice?.winboardMenus?.[0];
  const planChoiceWithWinboardMenu = planChoice
    ? {
        ...planChoice,
        winboardMenu,
      }
    : undefined;

  const { data: getWinboardMenusData } =
    useWinboardPlanOptionsEditPlanChoiceModalGetWinboardMenusQuery(
      company?.id ? { variables: { companyId: company.id } } : { skip: true },
    );
  const winboardMenus = useMemo(
    () => getWinboardMenusData?.winboardMenu ?? [],
    [getWinboardMenusData],
  );

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useWinboardPlanOptionsUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardMenuInsertInput, "companyId">) => {
      if (!company || !planChoice) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto: WinboardMenuInsertInput = {
        id,
        planId: plan.id,
        _planId: plan.planId,
        planChoiceId: planChoice.id,
        _planChoiceId: planChoice.planChoiceId,
        companyId: company.id,
        ...input,
      };

      try {
        await updateWinboardMenuMutation({
          variables: { winboardMenu: winboardMenuDto },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      onCompleted();
    },
    [
      company,
      planChoice,
      winboardMenu?.id,
      plan.id,
      plan.planId,
      onCompleted,
      updateWinboardMenuMutation,
    ],
  );

  return (
    <Modal title={planChoice?.name} open width={900} onCancel={onCloseModal} footer={null}>
      {loading && <Loading fullWidth height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planChoiceWithWinboardMenu && (
        <EditPlanChoiceWinboardMenuForm
          planChoice={planChoiceWithWinboardMenu}
          winboardMenus={winboardMenus}
          onSubmit={onSubmit}
          onClose={onCloseModal}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </Modal>
  );
});
