import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { WinboardMenuInsertInput } from "types/graphql";

import { EditMenuWinboardMenuForm } from "./EditMenuWinboardMenuForm";
import {
  useEditMenuWinboardMenuGetMenuCodeQuery,
  useEditMenuWinboardMenuGetMenuQuery,
  useEditMenuWinboardMenuUpdateWinboardMenuMutation,
} from "./queries";

export const EditMenuWinboardMenu = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
    error: errorGetMenu,
  } = useEditMenuWinboardMenuGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];

  const winboardMenu = menu?.winboardMenus[0];

  const companyId = menu?.companyId;

  const {
    data: getMenuCodesData,
    loading: loadingGetMenuCodes,
    error: errorGetMenuCodes,
  } = useEditMenuWinboardMenuGetMenuCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useEditMenuWinboardMenuUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardMenuInsertInput, "companyId" | "_companyId">) => {
      if (!company || !menu) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto: WinboardMenuInsertInput = {
        id,
        menuId: menu.id,
        _menuId: menu.menuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updateWinboardMenuMutation({
          variables: { winboardMenu: winboardMenuDto },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchMenu();
    },
    [company, menu, winboardMenu?.id, refetchMenu, updateWinboardMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingMenu || loadingGetMenuCodes;

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getMenuCodesData?.winboardMenu ?? [])
      .map((c) => (!isNaN(Number(c.code)) ? Number(c.code) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? (Math.max(...menuCodes) + 1).toString() : "1";
  }, [getMenuCodesData?.winboardMenu]);

  const shouldShowNetworkErrorAlert = Boolean(errorGetMenu || errorGetMenuCodes);

  const canEditAllShopMenus = menu?.totalDealingShopCount === menu?.shopMenus.length;
  const isEditMenuFeatureEnabled = isFeatureEnabled("editMenu");
  const winboardMenus = useMemo(() => getMenuCodesData?.winboardMenu ?? [], [getMenuCodesData]);

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ウィンボードメニュー" }],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(shouldShowNetworkErrorAlert)}
        shouldShowManagingShopErrorAlert={isEditMenuFeatureEnabled && !canEditAllShopMenus}
      />
      {loading && <Loading height={300} />}
      {menu && (
        <EditMenuWinboardMenuForm
          menu={menu}
          winboardMenus={winboardMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </DashboardLayout>
  );
};
