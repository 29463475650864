import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { PikaichiMenuInsertInput } from "types/graphql";

import { EditPlanChoicePikaichiMenuForm } from "./EditPlanChoicePikaichiMenuForm";
import {
  usePikaichiPlanOptionsEditPlanChoiceModalGetPikaichiMenusQuery,
  usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery,
  usePikaichiPlanOptionsUpdatePikaichiMenuMutation,
} from "./queries";

type Props = {
  onCompleted: () => void;
  onCloseModal: () => void;
};

export const EditPlanChoiceModal = memo<Props>(({ onCloseModal, onCompleted }) => {
  const [company, setCompany] = useCompany();
  const { planChoiceId: id } = useParams<{ planChoiceId: string }>();
  const planChoiceId = Number(id);

  if (Number.isNaN(planChoiceId)) {
    onCloseModal();
  }

  const {
    data: getPlanChoiceData,
    loading,
    error,
  } = usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery({
    variables: { planChoiceId },
  });
  const planChoice = getPlanChoiceData?.planChoice?.[0];
  const pikaichiMenu = planChoice?.pikaichiMenuPlanChoices?.[0]?.pikaichiMenu;

  const companyId = planChoice?.planOption.plan.companyId;
  setCompany(companyId);

  const { data: getPikaichiMenusData } =
    usePikaichiPlanOptionsEditPlanChoiceModalGetPikaichiMenusQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const pikaichiMenus = useMemo(
    () => getPikaichiMenusData?.pikaichiMenu ?? [],
    [getPikaichiMenusData],
  );

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    usePikaichiPlanOptionsUpdatePikaichiMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiMenuInsertInput, "companyId">) => {
      if (!company || !planChoice) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();
      const pikaichiMenuDto: PikaichiMenuInsertInput = {
        pikaichiMenuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updatePikaichiMenuMutation({
          variables: {
            planChoiceId: planChoice.id,
            _planChoiceId: planChoice.planChoiceId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      onCompleted();
    },
    [company, planChoice, pikaichiMenu?.pikaichiMenuId, onCompleted, updatePikaichiMenuMutation],
  );

  return (
    <Modal title={planChoice?.name} open width={900} onCancel={onCloseModal} footer={null}>
      {loading && <Loading fullWidth height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planChoice && (
        <EditPlanChoicePikaichiMenuForm
          planChoice={planChoice}
          pikaichiMenus={pikaichiMenus}
          onSubmit={onSubmit}
          onClose={onCloseModal}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </Modal>
  );
});
