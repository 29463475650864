import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { WinboardMenuInsertInput } from "types/graphql";

import { Menu } from "../types";

import { EditChoiceWinboardMenuForm } from "./EditChoiceWinboardMenuForm";
import {
  useWinboardMenuOptionsEditChoiceModalGetChoiceQuery,
  useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery,
  useWinboardMenuOptionsUpdateWinboardMenuMutation,
} from "./queries";

type Props = {
  menu: Menu;
  onCompleted: () => void;
  onCloseModal: () => void;
};

export const EditChoiceModal = memo<Props>(({ menu, onCompleted, onCloseModal }) => {
  const { choiceId: id } = useParams<{ choiceId: string }>();
  const choiceId = Number(id);

  if (Number.isNaN(choiceId)) {
    onCloseModal();
  }

  const [company] = useCompany();

  const {
    data: getChoiceData,
    loading,
    error,
  } = useWinboardMenuOptionsEditChoiceModalGetChoiceQuery({
    variables: { menuId: menu.menuId, choiceId },
  });
  const choice = getChoiceData?.choice?.[0];
  const winboardMenu = choice?.winboardMenus?.[0];
  const choiceWithWinboardMenu = choice
    ? {
        ...choice,
        winboardMenu,
      }
    : undefined;

  const { data: getWinboardMenusData } = useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery(
    menu?.companyId ? { variables: { companyId: menu?.companyId } } : { skip: true },
  );
  const winboardMenus = useMemo(
    () => getWinboardMenusData?.winboardMenu ?? [],
    [getWinboardMenusData],
  );

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useWinboardMenuOptionsUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardMenuInsertInput, "companyId">) => {
      if (!company) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto: WinboardMenuInsertInput = {
        id,
        menuId: menu.id,
        _menuId: menu.menuId,
        _choiceId: choiceId,
        companyId: company.id,
        ...input,
      };

      try {
        await updateWinboardMenuMutation({
          variables: { winboardMenu: winboardMenuDto },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      onCompleted();
    },
    [
      company,
      winboardMenu?.id,
      menu.id,
      menu.menuId,
      choiceId,
      onCompleted,
      updateWinboardMenuMutation,
    ],
  );

  return (
    <Modal title={choice?.name} open width={900} onCancel={onCloseModal} footer={null}>
      {loading && <Loading fullWidth height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {choiceWithWinboardMenu && (
        <EditChoiceWinboardMenuForm
          choice={choiceWithWinboardMenu}
          winboardMenus={winboardMenus}
          onSubmit={onSubmit}
          onClose={onCloseModal}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </Modal>
  );
});
