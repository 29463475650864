import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { message } from "antd";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { PikaichiMenuInsertInput, PikaichiMenuPlanChoiceInsertInput } from "types/graphql";

import { EditPlanPikaichiPlanFormValues } from "./EditPlanPikaichiPlanForm/useEditPlanPikaichiPlanForm";
import { EditPlanPikaichiPlanForm } from "./EditPlanPikaichiPlanForm";
import {
  useEditPlanPikaichiPlanGetPikaichiMenuCdQuery,
  useEditPlanPikaichiPlanGetPlanQuery,
  useEditPlanPikaichiPlanUpdatePikaichiMenuMutation,
} from "./queries";

export const EditPlanPikaichiPlan = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    loading: loadingPlan,
    refetch: refetchPlan,
    error: errorGetPlan,
  } = useEditPlanPikaichiPlanGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];

  const pikaichiMenu = plan?.pikaichiMenuPlans?.[0]?.pikaichiMenu;

  const companyId = plan?.companyId;

  const {
    data: getPikaichiMenuCodesData,
    loading: loadingGetPikaichiMenuCodes,
    error: errorGetPikaichiMenuCodes,
  } = useEditPlanPikaichiPlanGetPikaichiMenuCdQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    useEditPlanPikaichiPlanUpdatePikaichiMenuMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (input: EditPlanPikaichiPlanFormValues) => {
      if (!company || !plan) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();

      const pikaichiMenuDto: PikaichiMenuInsertInput = {
        pikaichiMenuId,
        companyId: company.id,
        pikaichiMenuName: input.pikaichiMenuName,
        pikaichiMenuCd: input.pikaichiMenuCd,
        pikaichiBumonCd: input.pikaichiBumonCd,
        pikaichiBumonName: input.pikaichiBumonName,
        pikaichiCategoryCd: input.pikaichiCategoryCd,
        pikaichiCategoryName: input.pikaichiCategoryName,
      };

      const dtoSources =
        input.plan?.planOptions.flatMap((option) =>
          option.planChoices.flatMap((planChoice) => {
            const planChoiceId = plan.planOptions
              .flatMap((o) => o.planChoices)
              .find((c) => c.planChoiceId === planChoice.planChoiceId)?.id;

            if (!planChoiceId) throw new Error("PlanChoice not found");

            return planChoice.pikaichiMenuPlanChoices.flatMap((v) => {
              const pikaichiMenuId = v.pikaichiMenuId ?? uuidv4();

              const menu: PikaichiMenuInsertInput = {
                pikaichiMenuId,
                companyId: company.id,
                pikaichiMenuName: v.pikaichiMenu.pikaichiMenuName,
                pikaichiMenuCd: v.pikaichiMenu.pikaichiMenuCd,
                pikaichiBumonCd: v.pikaichiMenu.pikaichiBumonCd,
                pikaichiBumonName: v.pikaichiMenu.pikaichiBumonName,
                pikaichiCategoryCd: v.pikaichiMenu.pikaichiCategoryCd,
                pikaichiCategoryName: v.pikaichiMenu.pikaichiCategoryName,
              };

              const choice: PikaichiMenuPlanChoiceInsertInput = {
                pikaichiMenuId,
                planChoiceId,
                _planChoiceId: planChoice.planChoiceId,
              };

              return { menu, choice };
            });
          }),
        ) ?? [];

      const pikaichiMenusDto = dtoSources.map(({ menu }) => menu);
      const pikaichiMenuPlanChoicesDto = dtoSources.map(({ choice }) => choice);

      try {
        await updatePikaichiMenuMutation({
          variables: {
            planId: plan.id,
            _planId: plan.planId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
            pikaichiMenus: pikaichiMenusDto,
            pikaichiMenuPlanChoices: pikaichiMenuPlanChoicesDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      await refetchPlan();
    },
    [company, plan, pikaichiMenu?.pikaichiMenuId, refetchPlan, updatePikaichiMenuMutation],
  );

  const loading = loadingPlan || loadingGetPikaichiMenuCodes;

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getPikaichiMenuCodesData?.pikaichiMenu ?? [])
      .map((c) => (!isNaN(Number(c.pikaichiMenuCd)) ? Number(c.pikaichiMenuCd) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getPikaichiMenuCodesData?.pikaichiMenu]);

  const shouldShowNetworkErrorAlert = Boolean(errorGetPlan || errorGetPikaichiMenuCodes);
  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");
  const pikaichiMenus = useMemo(
    () => getPikaichiMenuCodesData?.pikaichiMenu ?? [],
    [getPikaichiMenuCodesData],
  );

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビプラン" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={shouldShowNetworkErrorAlert}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loading && <Loading height={300} />}
      {plan && (
        <EditPlanPikaichiPlanForm
          plan={plan}
          pikaichiMenus={pikaichiMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </DashboardLayout>
  );
};
