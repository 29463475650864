import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuWinboardMenuGetMenu = gql`
    query EditMenuWinboardMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const EditMenuWinboardMenuGetMenuCode = gql`
    query EditMenuWinboardMenuGetMenuCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
    id
    name
  }
}
    `;
export const EditMenuWinboardMenuUpdateWinboardMenu = gql`
    mutation EditMenuWinboardMenuUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, menuId, _menuId]}
  ) {
    id
  }
}
    `;
export type EditMenuWinboardMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuWinboardMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type EditMenuWinboardMenuGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuWinboardMenuGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { winboardMenu: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'code' | 'id' | 'name'>
  )> }
);

export type EditMenuWinboardMenuUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
}>;


export type EditMenuWinboardMenuUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const EditMenuWinboardMenuGetMenuDocument = gql`
    query EditMenuWinboardMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useEditMenuWinboardMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuWinboardMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuWinboardMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuWinboardMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuWinboardMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuWinboardMenuGetMenuQuery, EditMenuWinboardMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuWinboardMenuGetMenuQuery, EditMenuWinboardMenuGetMenuQueryVariables>(EditMenuWinboardMenuGetMenuDocument, options);
      }
export function useEditMenuWinboardMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuWinboardMenuGetMenuQuery, EditMenuWinboardMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuWinboardMenuGetMenuQuery, EditMenuWinboardMenuGetMenuQueryVariables>(EditMenuWinboardMenuGetMenuDocument, options);
        }
export type EditMenuWinboardMenuGetMenuQueryHookResult = ReturnType<typeof useEditMenuWinboardMenuGetMenuQuery>;
export type EditMenuWinboardMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuWinboardMenuGetMenuLazyQuery>;
export type EditMenuWinboardMenuGetMenuQueryResult = Apollo.QueryResult<EditMenuWinboardMenuGetMenuQuery, EditMenuWinboardMenuGetMenuQueryVariables>;
export const EditMenuWinboardMenuGetMenuCodeDocument = gql`
    query EditMenuWinboardMenuGetMenuCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
    id
    name
  }
}
    `;

/**
 * __useEditMenuWinboardMenuGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditMenuWinboardMenuGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuWinboardMenuGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuWinboardMenuGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuWinboardMenuGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditMenuWinboardMenuGetMenuCodeQuery, EditMenuWinboardMenuGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuWinboardMenuGetMenuCodeQuery, EditMenuWinboardMenuGetMenuCodeQueryVariables>(EditMenuWinboardMenuGetMenuCodeDocument, options);
      }
export function useEditMenuWinboardMenuGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuWinboardMenuGetMenuCodeQuery, EditMenuWinboardMenuGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuWinboardMenuGetMenuCodeQuery, EditMenuWinboardMenuGetMenuCodeQueryVariables>(EditMenuWinboardMenuGetMenuCodeDocument, options);
        }
export type EditMenuWinboardMenuGetMenuCodeQueryHookResult = ReturnType<typeof useEditMenuWinboardMenuGetMenuCodeQuery>;
export type EditMenuWinboardMenuGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditMenuWinboardMenuGetMenuCodeLazyQuery>;
export type EditMenuWinboardMenuGetMenuCodeQueryResult = Apollo.QueryResult<EditMenuWinboardMenuGetMenuCodeQuery, EditMenuWinboardMenuGetMenuCodeQueryVariables>;
export const EditMenuWinboardMenuUpdateWinboardMenuDocument = gql`
    mutation EditMenuWinboardMenuUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, menuId, _menuId]}
  ) {
    id
  }
}
    `;
export type EditMenuWinboardMenuUpdateWinboardMenuMutationFn = Apollo.MutationFunction<EditMenuWinboardMenuUpdateWinboardMenuMutation, EditMenuWinboardMenuUpdateWinboardMenuMutationVariables>;

/**
 * __useEditMenuWinboardMenuUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useEditMenuWinboardMenuUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuWinboardMenuUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuWinboardMenuUpdateWinboardMenuMutation, { data, loading, error }] = useEditMenuWinboardMenuUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *   },
 * });
 */
export function useEditMenuWinboardMenuUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuWinboardMenuUpdateWinboardMenuMutation, EditMenuWinboardMenuUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuWinboardMenuUpdateWinboardMenuMutation, EditMenuWinboardMenuUpdateWinboardMenuMutationVariables>(EditMenuWinboardMenuUpdateWinboardMenuDocument, options);
      }
export type EditMenuWinboardMenuUpdateWinboardMenuMutationHookResult = ReturnType<typeof useEditMenuWinboardMenuUpdateWinboardMenuMutation>;
export type EditMenuWinboardMenuUpdateWinboardMenuMutationResult = Apollo.MutationResult<EditMenuWinboardMenuUpdateWinboardMenuMutation>;
export type EditMenuWinboardMenuUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<EditMenuWinboardMenuUpdateWinboardMenuMutation, EditMenuWinboardMenuUpdateWinboardMenuMutationVariables>;