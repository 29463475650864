import React, { memo } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { PikaichiMenuInsertInput } from "types/graphql";

import { PikaichiMenu, PlanChoice } from "../../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { useEditPlanChoicePikaichiMenuForm } from "./useEditPlanChoicePikaichiMenuForm";

type Props = {
  planChoice: PlanChoice;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPlanChoicePikaichiMenuForm = memo<Props>(
  ({ planChoice, pikaichiMenus, onClose, onSubmit, loading }) => {
    const pikaichiMenu = planChoice?.pikaichiMenuPlanChoices?.[0]?.pikaichiMenu;
    const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId;

    const { form, initialValues, submit } = useEditPlanChoicePikaichiMenuForm({
      planChoice,
      pikaichiMenu,
      onSubmit,
    });

    return (
      <Form name="pikaichiMenu" form={form} layout="vertical" initialValues={initialValues}>
        <Row>
          <Col span={24}>
            <PikaichiMenuCdField pikaichiMenus={pikaichiMenus} pikaichiMenuId={pikaichiMenuId} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PikaichiMenuNameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PikaichiBumonCdField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PikaichiBumonNameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PikaichiCategoryCdField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PikaichiCategoryNameField />
          </Col>
        </Row>

        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            保存
          </Button>
        </FormActions>
      </Form>
    );
  },
);
