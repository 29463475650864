import { useMemo } from "react";
import dayjs from "dayjs";
import { isNumber } from "lodash";
import { isNotNull } from "util/type/primitive";

import { dayjsToDateString } from "libs/DateString";
import { CompanySalesAnalyticsOutput, ShopBusinessOperationHour } from "types/graphql";

import { useGetCompanySalesAnalyticsQuery } from "./queries";
import { CompanySalesAnalyticsRow, NormalizedCompanySalesAnalyticsRow } from "./types";

const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => Object.keys(obj);

const defaultFixedDecimalPoint = 3;

const calcPercentage = ({ top, bottom }: { top: number; bottom: number }) =>
  Number((top / bottom).toFixed(defaultFixedDecimalPoint));

const normalizeTaxSettings = ({
  rows,
  showTaxIncluded,
  businessOperationHourTypes,
}: {
  rows: CompanySalesAnalyticsOutput["rows"];
  showTaxIncluded: boolean;
  businessOperationHourTypes: Pick<
    ShopBusinessOperationHour,
    "businessOperationHourType" | "start" | "end"
  >[];
}): CompanySalesAnalyticsRow[] => {
  const rowsWithAmountByTaxSetting = rows.map((row) => ({
    name: row.name,
    isSummaryRow: false,
    shopId: row.shopId,
    businessDaysCount: row.businessDaysCount,
    numPeople: row.numPeople,
    eatInNumPeople: row.eatInNumPeople,
    takeOutNumPeople: row.takeOutNumPeople,
    groupCount: row.groupCount,
    checkedInGroupCount: row.checkedInGroupCount,
    customerCount: row.customerCount,
    dinnerCustomerCount: row.dinnerCustomerCount,
    lunchCustomerCount: row.lunchCustomerCount,
    repeatVisitCustomerCount: row.repeatVisitCustomerCount,
    newCustomerCount: row.newCustomerCount,
    ambassadorCount: row.ambassadorCount,
    introducedCustomerCount: row.introducedCustomerCount,
    mobileOrderQuantity: row.mobileOrderQuantity,
    nonMobileOrderQuantity: row.nonMobileOrderQuantity,

    salesTargetAmount: isNumber(row.salesTargetAmount)
      ? showTaxIncluded
        ? // NOTE: 税計算の方法。通常、税込売上額は、商品1つ1つに税金をかけて合計を計算するが、売上目標金額は何らかの合算ではないため、ここの実装ではシンプルに税金をかけた。
          Math.floor(row.salesTargetAmount * 1.1)
        : row.salesTargetAmount
      : null,
    totalAmount: showTaxIncluded ? row.totalTaxIncludedAmount : row.totalTaxExcludedAmount,
    totalTakeOutAmount: showTaxIncluded
      ? row.takeOutTotalTaxIncludedAmount
      : row.takeOutTotalTaxExcludedAmount,
    totalEatInAmount: showTaxIncluded
      ? row.eatInTotalTaxIncludedAmount
      : row.eatInTotalTaxExcludedAmount,
    totalCostAmount: showTaxIncluded
      ? row.totalTaxIncludedCostAmount || null
      : row.totalTaxExcludedCostAmount || null,
    grossProfitAmount: showTaxIncluded
      ? row.totalTaxIncludedNetProfitAmount
      : row.totalTaxExcludedNetProfitAmount,
    repeaterTableTotalAmount: showTaxIncluded
      ? row.repeaterTableTotalTaxIncludedAmount
      : row.repeaterTableTotalTaxExcludedAmount,
    dinnerTotalAmount: showTaxIncluded
      ? row.dinnerTotalTaxIncludedAmount
      : row.dinnerTotalTaxExcludedAmount,
    lunchTotalAmount: showTaxIncluded
      ? row.lunchTotalTaxIncludedAmount
      : row.lunchTotalTaxExcludedAmount,
    faveYellTotalAmount: showTaxIncluded
      ? row.faveYellTotalTaxIncludedAmount
      : row.faveYellTotalTaxExcludedAmount,
    drinkTotalAmount: showTaxIncluded
      ? row.drinkTotalTaxIncludedAmount
      : row.drinkTotalTaxExcludedAmount,
    foodTotalAmount: showTaxIncluded
      ? row.foodTotalTaxIncludedAmount
      : row.foodTotalTaxExcludedAmount,
    otherTotalAmount: showTaxIncluded
      ? row.otherTotalTaxIncludedAmount
      : row.otherTotalTaxExcludedAmount,
    planTotalAmount: showTaxIncluded
      ? row.planTotalTaxIncludedAmount
      : row.planTotalTaxExcludedAmount,
    takeoutTotalAmount: showTaxIncluded
      ? row.takeOutTotalTaxIncludedAmount
      : row.takeOutTotalTaxExcludedAmount,
  }));

  const firstRow = rowsWithAmountByTaxSetting[0];

  if (!firstRow) return [];

  const summaryRow: typeof firstRow = {
    name: "合計",
    isSummaryRow: true,
    shopId: "",
    ...Object.fromEntries(
      getKeys(firstRow)
        .map((key) =>
          key !== "name" && key !== "shopId" && key !== "isSummaryRow"
            ? [
                key,
                rowsWithAmountByTaxSetting.reduce<number | null>((acc, current) => {
                  const value = current[key];

                  return acc === null ? value : value === null ? acc : acc + value;
                }, null),
              ]
            : null,
        )
        .filter(isNotNull),
    ),
  };

  const normalizedRows: NormalizedCompanySalesAnalyticsRow[] = [
    summaryRow,
    ...rowsWithAmountByTaxSetting,
  ].map((row) => ({
    ...row,
    isEmpty: false,
    shopName: String(row.name),
    shopId: String(row.shopId),
    eatInSalesPerCustomer: Math.round(row.totalEatInAmount / row.eatInNumPeople),
    takeOutSalesPerCustomer: Math.round(row.takeoutTotalAmount / row.takeOutNumPeople),
    salesPerCustomerAmount: Math.round(row.totalAmount / row.numPeople),
    previousMonthSameWeekDayPercentage: 0, // TODO: implement
    reservationGroupCount: 0, // TODO: implement
    reservationCustomerCount: 0, // TODO: implement
    reservationTotalAmount: 0, // TODO: implement
    grossProfitPercentage: isNumber(row.totalCostAmount)
      ? calcPercentage({
          top: row.totalAmount - row.totalCostAmount,
          bottom: row.totalAmount,
        })
      : null,
    goalCompletionPercentage: isNumber(row.salesTargetAmount)
      ? calcPercentage({ top: row.totalAmount, bottom: row.salesTargetAmount })
      : null,
    goalDifference: isNumber(row.salesTargetAmount)
      ? row.totalAmount - row.salesTargetAmount
      : null,
    costPercentage: row.totalCostAmount
      ? calcPercentage({ top: row.totalCostAmount, bottom: row.totalAmount })
      : null,
    groupCheckInPercentage: calcPercentage({
      top: row.checkedInGroupCount,
      bottom: row.groupCount,
    }),
    customerCheckInPercentage: calcPercentage({ top: row.customerCount, bottom: row.numPeople }),
    salesPerCustomer: Math.round(row.totalAmount / row.numPeople),
    dinnerSalesPerCustomer: Math.round(row.dinnerTotalAmount / row.dinnerCustomerCount),
    lunchSalesPerCustomer: Math.round(row.lunchTotalAmount / row.lunchCustomerCount),
    repeaterPercentage: calcPercentage({
      top: row.repeatVisitCustomerCount,
      bottom: row.numPeople,
    }),
    eatInCustomerCount: row.eatInNumPeople,
    notCheckedInNumPeople: row.numPeople - row.customerCount,
    repeaterSalesPercentage: calcPercentage({
      top: row.repeaterTableTotalAmount,
      bottom: row.totalAmount,
    }),
    mobileOrderPercentage: calcPercentage({
      top: row.mobileOrderQuantity,
      bottom: row.mobileOrderQuantity + row.nonMobileOrderQuantity,
    }),
  }));

  return normalizedRows;
};

export const useCompanySalesAnalytics = ({
  allShopIds: allShopIds,
  selectedShopIds,
  selectedBusinessOperationHourTypes,
  startDate,
  endDate,
  showTaxIncluded,
  businessOperationHourTypes,
}: {
  allShopIds: string[];
  selectedShopIds: string[] | null;
  selectedBusinessOperationHourTypes: string[];
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  showTaxIncluded: boolean;
  businessOperationHourTypes: Pick<
    ShopBusinessOperationHour,
    "businessOperationHourType" | "start" | "end"
  >[];
}) => {
  const {
    data: companySalesAnalyticsData,
    loading,
    error,
  } = useGetCompanySalesAnalyticsQuery(
    allShopIds.length > 0 && startDate && endDate
      ? {
          variables: {
            input: {
              shopIds: allShopIds,
              startAt: dayjsToDateString(startDate),
              endAt: dayjsToDateString(endDate),
            },
          },
        }
      : { skip: true },
  );

  const rows = useMemo(
    () =>
      companySalesAnalyticsData?.companySalesAnalytics.rows.filter(
        // NOTE: selectedShopIds が null の場合は全店舗を扱う
        ({ shopId }) => !selectedShopIds || selectedShopIds.includes(shopId),
      ) ?? [],
    [companySalesAnalyticsData?.companySalesAnalytics.rows, selectedShopIds],
  );

  const normalizedRows = useMemo(
    () =>
      normalizeTaxSettings({
        showTaxIncluded,
        rows,
        businessOperationHourTypes,
      }),
    [businessOperationHourTypes, rows, showTaxIncluded],
  );

  return {
    normalizedRows,
    isLoading: loading,
  };
};
