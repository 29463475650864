import React, { memo } from "react";
import { getRole } from "models/user";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { useCurrentUser } from "hooks/useUser";
import { DashboardFeatureEnum } from "types/graphql";
import { Role } from "types/role";

type Props = SubMenuPropsWithoutFeatures;

export const FoodingJournalMenu = memo<Props>(({ ...props }) => {
  const user = useCurrentUser();
  const role = getRole(user);

  const canShowMenuCodeCsv = role === Role.ServiceAdmin;

  return (
    <SubMenu
      key="tec"
      title="Fooding Journal連携"
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      <MenuItem route="foodingJournalUpload" to="/foodingJournal/upload" text="連携処理" />
      <MenuItem route="foodingJournalMasters" to="/foodingJournal/masters" text="部門・分類設定" />
      <MenuItem route="foodingJournalMenus" to="/foodingJournal/menu" text="メニューコード設定" />
      <MenuItem route="foodingJournalPlans" to="/foodingJournal/plan" text="プランコード設定" />
      <MenuItem
        route="foodingJournalOnSitePaymentDetailTypes"
        to="/foodingJournal/onSitePaymentDetailTypes"
        text="会計種別設定"
      />
      <MenuItem
        route="foodingJournalOnSitePaymentDiscountTypes"
        to="/foodingJournal/onSitePaymentDiscountTypes"
        text="割引・値引種別設定"
      />
      <MenuItem route="editFoodingJournalConfig" to="/foodingJournal/config" text="設定" />
      <MenuItem
        route="menuCodeCsv"
        to="/menuCodeCsv/foodingJournal"
        canShow={canShowMenuCodeCsv}
        text="メニューコード一括編集"
      />
    </SubMenu>
  );
});
