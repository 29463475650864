import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { Alert, message } from "antd";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { useCompany } from "hooks/useCompany";
import { PikaichiMenuChoiceInsertInput, PikaichiMenuInsertInput } from "types/graphql";

import { EditOptionPikaichiOptionFormValues } from "./EditOptionPikaichiOptionForm/useEditOptionPikaichiOptionForm";
import { EditOptionPikaichiOptionForm } from "./EditOptionPikaichiOptionForm";
import {
  EditOptionPikaichiOptionGetOptionDocument,
  useEditOptionPikaichiOptionGetOptionQuery,
  useEditOptionPikaichiOptionGetPikaichiMenuCdQuery,
  useEditOptionPikaichiOptionUpdateChoicesMutation,
} from "./queries";

export const EditOptionPikaichiOption = () => {
  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);
  const navigate = useNavigate();

  const {
    data: getOptionData,
    loading: loadingOption,
    error: errorGetOption,
  } = useEditOptionPikaichiOptionGetOptionQuery(
    !Number.isNaN(optionId) ? { variables: { optionId } } : { skip: true },
  );
  const option = getOptionData?.option?.[0];

  const companyId = company?.id;

  const {
    data: getPikaichiMenuCodesData,
    loading: loadingGetPikaichiMenuCodes,
    error: errorGetPikaichiMenuCodes,
  } = useEditOptionPikaichiOptionGetPikaichiMenuCdQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updatePikaichiChoicesMutation, { loading: loadingUpdatePikaichiChoices }] =
    useEditOptionPikaichiOptionUpdateChoicesMutation();

  const onSubmit = useCallback(
    async (input: EditOptionPikaichiOptionFormValues) => {
      if (!company) return;

      const dtoSources =
        input.option.choices.flatMap((optionChoice) => {
          const choice = option?.choices.find((c) => c.choiceId === optionChoice.choiceId);

          if (!choice) throw new Error("Choice not found");

          return optionChoice.pikaichiMenuChoices?.flatMap((v) => {
            const id = v.pikaichiMenu.pikaichiMenuId ?? uuidv4();

            const pikaichiMenu: PikaichiMenuInsertInput = {
              pikaichiMenuId: id,
              companyId: company.id,
              pikaichiMenuName: v.pikaichiMenu.pikaichiMenuName,
              pikaichiMenuCd: v.pikaichiMenu.pikaichiMenuCd,
              pikaichiBumonCd: v.pikaichiMenu.pikaichiBumonCd,
              pikaichiBumonName: v.pikaichiMenu.pikaichiBumonName,
              pikaichiCategoryCd: v.pikaichiMenu.pikaichiCategoryCd,
              pikaichiCategoryName: v.pikaichiMenu.pikaichiCategoryName,
            };

            const pikaichiMenuChoice: PikaichiMenuChoiceInsertInput = {
              pikaichiMenuId: id,
              choiceId: choice.id,
              _choiceId: choice.choiceId,
            };

            return { pikaichiMenu, pikaichiMenuChoice };
          });
        }) ?? [];

      const pikaichiMenusDto = dtoSources.map(({ pikaichiMenu }) => pikaichiMenu);
      const pikaichiMenuChoicesDto = dtoSources.map(({ pikaichiMenuChoice }) => pikaichiMenuChoice);

      try {
        await updatePikaichiChoicesMutation({
          variables: {
            pikaichiMenus: pikaichiMenusDto,
            pikaichiMenuChoices: pikaichiMenuChoicesDto,
          },
          refetchQueries: [
            {
              query: EditOptionPikaichiOptionGetOptionDocument,
              variables: { optionId },
            },
          ],
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, option?.choices, optionId, updatePikaichiChoicesMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getPikaichiMenuCodesData?.pikaichiMenu ?? [])
      .map((c) => (!isNaN(Number(c.pikaichiMenuCd)) ? Number(c.pikaichiMenuCd) : null))
      .filter(isNotNull);
    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getPikaichiMenuCodesData?.pikaichiMenu]);

  const loading = loadingOption || loadingUpdatePikaichiChoices || loadingGetPikaichiMenuCodes;

  const shouldShowNetworkErrorAlert = Boolean(errorGetOption || errorGetPikaichiMenuCodes);

  const pikaichiMenus = useMemo(
    () => getPikaichiMenuCodesData?.pikaichiMenu ?? [],
    [getPikaichiMenuCodesData],
  );

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビオプション" }],
      }}
    >
      <OptionHeader option={option ?? null} onBack={goBack} />
      {shouldShowNetworkErrorAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {option && (
        <EditOptionPikaichiOptionForm
          option={option}
          pikaichiMenus={pikaichiMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
