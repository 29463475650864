import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { PlanChoice, WinboardMenu } from "../../../types";
import { EditPlanWinboardPlanFormItem } from "../../useEditPlanWinboardPlanForm";

type Props = {
  optionField: FormListFieldData;
  loading?: boolean;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code">[];
};

type FormListFieldDataSource = {
  planOptionField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
  planChoiceField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
};

type rowItem = Pick<PlanChoice, "name" | "planChoiceId"> & {
  winboardMenus: Array<
    Pick<
      WinboardMenu,
      "id" | "code" | "name" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
    >
  >;
};

export const PlanChoiceTable = memo<Props>(({ loading, optionField, winboardMenus }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ plan }) => [
              plan?.planOptions.map((option) => {
                option.planChoices.map((choice) => choice.name);
              }),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue([
                "plan",
                "planOptions",
                field.planOptionField.name,
                "planChoices",
                field.planChoiceField.name,
                "name",
              ]);

              return <span>{choiceName}</span>;
            }}
          </EditPlanWinboardPlanFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "code",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem.NonProperty noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <EditPlanWinboardPlanFormItem
                name={[field.planChoiceField.name, "winboardMenus", 0, "code"]}
                rules={[
                  {
                    required: true,
                    pattern: /^\d{1,8}$/,
                    message: "8桁以内の数字で入力してください",
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) return;

                      const planChoices: rowItem[] =
                        getFieldValue([
                          "plan",
                          "planOptions",
                          field.planOptionField.name,
                          "planChoices",
                        ]) || [];
                      const currentPlanChoice = planChoices[field.planChoiceField.name];

                      const conflictingExistingWinboardMenu = winboardMenus.find(
                        ({ id, code }) =>
                          code === value && id !== currentPlanChoice?.winboardMenus[0]?.id,
                      );

                      if (conflictingExistingWinboardMenu) {
                        const editingWinboardMenu = planChoices.find(
                          ({ winboardMenus }) =>
                            winboardMenus[0]?.code !== value &&
                            winboardMenus[0]?.id === conflictingExistingWinboardMenu.id,
                        );

                        if (!editingWinboardMenu) {
                          throw new Error(
                            `既存のコードと重複しています (${conflictingExistingWinboardMenu.name})`,
                          );
                        }
                      }

                      const conflictingEditingWinboardMenu = planChoices.filter(
                        (planChoice) =>
                          planChoice.winboardMenus[0]?.code === value &&
                          planChoice.planChoiceId !== currentPlanChoice?.planChoiceId,
                      );

                      if (conflictingEditingWinboardMenu[0]) {
                        throw new Error(
                          `既存のコードと重複しています (${conflictingEditingWinboardMenu[0].winboardMenus[0]?.name})`,
                        );
                      }
                    },
                  },
                ]}
                endSpacer={null}
              >
                <InputCode />
              </EditPlanWinboardPlanFormItem>
            )}
          </EditPlanWinboardPlanFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            name={[field.planChoiceField.name, "winboardMenus", 0, "name"]}
            rules={[{ required: true, max: 30, message: "30文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "bumonCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            name={[field.planChoiceField.name, "winboardMenus", 0, "bumonCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "bumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            name={[field.planChoiceField.name, "winboardMenus", 0, "bumonName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "categoryCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            name={[field.planChoiceField.name, "winboardMenus", 0, "categoryCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "categoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            name={[field.planChoiceField.name, "winboardMenus", 0, "categoryName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={[optionField.name, "planChoices"]}>
      {(planChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={planChoiceFields.map((field) => ({
            planOptionField: optionField,
            planChoiceField: field,
          }))}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
