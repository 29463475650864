import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PikaichiMenuHeader } from "components/PageHeader/PikaichiMenuHeader";
import { useCompany } from "hooks/useCompany";
import { PikaichiMenuInsertInput } from "types/graphql";

import { EditPikaichiMenuForm } from "./EditPikaichiMenuForm";
import {
  useEditPikaichiMenuGetMenuQuery,
  useEditPikaichiMenuGetPikaichiMenusQuery,
  useEditPikaichiMenuUpdatePikaichiMenuMutation,
} from "./queries";

export const EditPikaichiMenu = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
    error,
  } = useEditPikaichiMenuGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];

  const pikaichiMenu = menu?.pikaichiMenuMenus?.[0]?.pikaichiMenu;

  const { data: getPikaichiMenusData } = useEditPikaichiMenuGetPikaichiMenusQuery(
    menu?.companyId ? { variables: { companyId: menu?.companyId } } : { skip: true },
  );
  const pikaichiMenus = useMemo(
    () => getPikaichiMenusData?.pikaichiMenu ?? [],
    [getPikaichiMenusData],
  );

  setCompany(menu?.companyId);

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    useEditPikaichiMenuUpdatePikaichiMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiMenuInsertInput, "companyId">) => {
      if (!company || !menu) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();
      const pikaichiMenuDto: PikaichiMenuInsertInput = {
        pikaichiMenuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updatePikaichiMenuMutation({
          variables: {
            menuId: menu.id,
            _menuId: menu.menuId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchMenu();
    },
    [company, menu, pikaichiMenu?.pikaichiMenuId, refetchMenu, updatePikaichiMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビメニュー" }],
      }}
    >
      <PikaichiMenuHeader menu={menu ?? null} onBack={goBack} />
      {loadingMenu && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menu && (
        <EditPikaichiMenuForm
          menu={menu}
          pikaichiMenus={pikaichiMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </DashboardLayout>
  );
};
