import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { WinboardPlanHeader } from "components/PageHeader/WinboardPlanHeader";
import { useCompany } from "hooks/useCompany";
import { WinboardMenuInsertInput } from "types/graphql";

import { EditWinboardPlanForm } from "./EditWinboardPlanForm";
import {
  useEditWinboardPlanGetPlanQuery,
  useEditWinboardPlanGetWinboardMenusQuery,
  useEditWinboardPlanUpdateWinboardMenuMutation,
} from "./queries";

export const EditWinboardPlan = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    loading: loadingPlan,
    error,
  } = useEditWinboardPlanGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];

  const winboardMenu = plan?.winboardMenus?.[0];

  const { data: getWinboardMenusData } = useEditWinboardPlanGetWinboardMenusQuery(
    plan?.companyId ? { variables: { companyId: plan?.companyId } } : { skip: true },
  );
  const winboardMenus = useMemo(
    () => getWinboardMenusData?.winboardMenu ?? [],
    [getWinboardMenusData],
  );

  setCompany(plan?.companyId);

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useEditWinboardPlanUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardMenuInsertInput, "companyId">) => {
      if (!company || !plan) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto = {
        id,
        planId: plan.id,
        _planId: plan.planId,
        companyId: company.id,
        ...input,
      };

      try {
        await updateWinboardMenuMutation({
          variables: { winboardMenu: winboardMenuDto },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, plan, winboardMenu?.id, updateWinboardMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ウィンボードプラン" }],
      }}
    >
      <WinboardPlanHeader plan={plan ?? null} onBack={goBack} />
      {loadingPlan && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {plan && (
        <EditWinboardPlanForm
          plan={plan}
          winboardMenus={winboardMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </DashboardLayout>
  );
};
