import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { WinboardMenuSetInput } from "types/graphql";

import { Menu, WinboardMenu } from "../types";

import { BumonCodeField } from "./BumonCodeField";
import { BumonNameField } from "./BumonNameField";
import { CategoryCodeField } from "./CategoryCodeField";
import { CategoryNameField } from "./CategoryNameField";
import { CodeField } from "./CodeField";
import { NameField } from "./NameField";
import { useEditWinboardMenuForm } from "./useEditWinboardMenuForm";

type Props = {
  menu: Menu;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code">[];
  onSubmit: (args: Omit<WinboardMenuSetInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditWinboardMenuForm = memo<Props>(
  ({ menu, winboardMenus, onClose, onSubmit, loading }) => {
    const winboardMenu = menu?.winboardMenus?.[0] ?? undefined;
    const winboardMenuId = winboardMenu?.id;

    const { form, initialValues, submit } = useEditWinboardMenuForm({
      menu,
      winboardMenu,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form name="winboardMenu" form={form} initialValues={initialValues} layout="vertical">
            <CodeField winboardMenus={winboardMenus} winboardMenuId={winboardMenuId} />
            <NameField />
            <BumonCodeField />
            <BumonNameField />
            <CategoryCodeField />
            <CategoryNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
