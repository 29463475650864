import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardMenuOptionsEditChoiceModalGetChoice = gql`
    query WinboardMenuOptionsEditChoiceModalGetChoice($menuId: Int!, $choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    choiceId
    price
    name
    winboardMenus(where: {_menuId: {_eq: $menuId}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const WinboardMenuOptionsEditChoiceModalGetWinboardMenus = gql`
    query WinboardMenuOptionsEditChoiceModalGetWinboardMenus($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    id
    name
    code
  }
}
    `;
export const WinboardMenuOptionsUpdateWinboardMenu = gql`
    mutation WinboardMenuOptionsUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, choiceId, _choiceId]}
  ) {
    id
  }
}
    `;
export type WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  choiceId: Types.Scalars['Int'];
}>;


export type WinboardMenuOptionsEditChoiceModalGetChoiceQuery = (
  { __typename?: 'query_root' }
  & { choice: Array<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'choiceId' | 'price' | 'name'>
    & { winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery = (
  { __typename?: 'query_root' }
  & { winboardMenu: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id' | 'name' | 'code'>
  )> }
);

export type WinboardMenuOptionsUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
}>;


export type WinboardMenuOptionsUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const WinboardMenuOptionsEditChoiceModalGetChoiceDocument = gql`
    query WinboardMenuOptionsEditChoiceModalGetChoice($menuId: Int!, $choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    choiceId
    price
    name
    winboardMenus(where: {_menuId: {_eq: $menuId}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useWinboardMenuOptionsEditChoiceModalGetChoiceQuery__
 *
 * To run a query within a React component, call `useWinboardMenuOptionsEditChoiceModalGetChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenuOptionsEditChoiceModalGetChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenuOptionsEditChoiceModalGetChoiceQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function useWinboardMenuOptionsEditChoiceModalGetChoiceQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenuOptionsEditChoiceModalGetChoiceQuery, WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenuOptionsEditChoiceModalGetChoiceQuery, WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables>(WinboardMenuOptionsEditChoiceModalGetChoiceDocument, options);
      }
export function useWinboardMenuOptionsEditChoiceModalGetChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenuOptionsEditChoiceModalGetChoiceQuery, WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenuOptionsEditChoiceModalGetChoiceQuery, WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables>(WinboardMenuOptionsEditChoiceModalGetChoiceDocument, options);
        }
export type WinboardMenuOptionsEditChoiceModalGetChoiceQueryHookResult = ReturnType<typeof useWinboardMenuOptionsEditChoiceModalGetChoiceQuery>;
export type WinboardMenuOptionsEditChoiceModalGetChoiceLazyQueryHookResult = ReturnType<typeof useWinboardMenuOptionsEditChoiceModalGetChoiceLazyQuery>;
export type WinboardMenuOptionsEditChoiceModalGetChoiceQueryResult = Apollo.QueryResult<WinboardMenuOptionsEditChoiceModalGetChoiceQuery, WinboardMenuOptionsEditChoiceModalGetChoiceQueryVariables>;
export const WinboardMenuOptionsEditChoiceModalGetWinboardMenusDocument = gql`
    query WinboardMenuOptionsEditChoiceModalGetWinboardMenus($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    id
    name
    code
  }
}
    `;

/**
 * __useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery__
 *
 * To run a query within a React component, call `useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery, WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery, WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables>(WinboardMenuOptionsEditChoiceModalGetWinboardMenusDocument, options);
      }
export function useWinboardMenuOptionsEditChoiceModalGetWinboardMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery, WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery, WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables>(WinboardMenuOptionsEditChoiceModalGetWinboardMenusDocument, options);
        }
export type WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryHookResult = ReturnType<typeof useWinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery>;
export type WinboardMenuOptionsEditChoiceModalGetWinboardMenusLazyQueryHookResult = ReturnType<typeof useWinboardMenuOptionsEditChoiceModalGetWinboardMenusLazyQuery>;
export type WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryResult = Apollo.QueryResult<WinboardMenuOptionsEditChoiceModalGetWinboardMenusQuery, WinboardMenuOptionsEditChoiceModalGetWinboardMenusQueryVariables>;
export const WinboardMenuOptionsUpdateWinboardMenuDocument = gql`
    mutation WinboardMenuOptionsUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, choiceId, _choiceId]}
  ) {
    id
  }
}
    `;
export type WinboardMenuOptionsUpdateWinboardMenuMutationFn = Apollo.MutationFunction<WinboardMenuOptionsUpdateWinboardMenuMutation, WinboardMenuOptionsUpdateWinboardMenuMutationVariables>;

/**
 * __useWinboardMenuOptionsUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useWinboardMenuOptionsUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenuOptionsUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [winboardMenuOptionsUpdateWinboardMenuMutation, { data, loading, error }] = useWinboardMenuOptionsUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *   },
 * });
 */
export function useWinboardMenuOptionsUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<WinboardMenuOptionsUpdateWinboardMenuMutation, WinboardMenuOptionsUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WinboardMenuOptionsUpdateWinboardMenuMutation, WinboardMenuOptionsUpdateWinboardMenuMutationVariables>(WinboardMenuOptionsUpdateWinboardMenuDocument, options);
      }
export type WinboardMenuOptionsUpdateWinboardMenuMutationHookResult = ReturnType<typeof useWinboardMenuOptionsUpdateWinboardMenuMutation>;
export type WinboardMenuOptionsUpdateWinboardMenuMutationResult = Apollo.MutationResult<WinboardMenuOptionsUpdateWinboardMenuMutation>;
export type WinboardMenuOptionsUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<WinboardMenuOptionsUpdateWinboardMenuMutation, WinboardMenuOptionsUpdateWinboardMenuMutationVariables>;