import React, { useCallback, useMemo } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { FractionalDiscountOnSitePaymentDiscountType } from "models/onSitePaymentDiscountType";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { createSimpleHashWithSHA256 } from "util/simpleHash";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import {
  ServiceChargeConfigInput,
  ShopDiscountTypeInsertInput,
  SpecificTimeSurchargeConfigInput,
} from "types/graphql";

import { EditCashRegisterConfigForm } from "./EditCashRegisterConfigForm/index";
import { AddAccountingSlipImageModal } from "./AddAccountingSlipImageModal";
import { EditAccountingSlipImageModal } from "./EditAccountingSlipImageModal";
import { PreviewAccountingSlipImageModal } from "./PreviewAccountingSlipImageModal";
import {
  useEditCashRegisterConfigCreateSurchargeConfigsMutation,
  useEditCashRegisterConfigGetDiscountTypesQuery,
  useEditCashRegisterConfigGetShopQuery,
  useEditCashRegisterConfigUpdateShopMutation,
} from "./queries";
import { ShopSetInputWithCashRegisterConfig } from "./types";

export const EditCashRegisterConfig = () => {
  const { id: shopId = "" } = useParams<{ id: string }>();

  const {
    data: getShopData,
    loading,
    refetch,
    error,
  } = useEditCashRegisterConfigGetShopQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shop = getShopData?.shop_by_pk;
  const companyId = shop?.companyId;
  const currentDrawerOpenPassword = shop?.cashRegisterConfig?.drawerOpenPassword;

  const { data: discountTypesData } = useEditCashRegisterConfigGetDiscountTypesQuery();

  const discountTypes = useMemo(
    () => discountTypesData?.onSitePaymentDiscountType ?? [],
    [discountTypesData?.onSitePaymentDiscountType],
  );

  useSetCurrentCompanyEffect(shop);

  const [updateCashRegisterConfigMutation, { loading: loadingUpdateCashRegisterConfig }] =
    useEditCashRegisterConfigUpdateShopMutation();

  const [createSurchargeConfigsMutation, { loading: loadingCreateSurchargeConfigs }] =
    useEditCashRegisterConfigCreateSurchargeConfigsMutation();

  const onSubmit = useCallback(
    async ({
      shop: {
        enableRequiringPasswordForDrawerOpen,
        drawerOpenPassword,
        useStera,
        salesRelatedSlipTaxConfig,
        ...shop
      },
      serviceChargeConfig,
      specificTimeSurchargeConfig,
    }: {
      shop: ShopSetInputWithCashRegisterConfig;
      serviceChargeConfig: ServiceChargeConfigInput | null;
      specificTimeSurchargeConfig: SpecificTimeSurchargeConfigInput | null;
    }) => {
      if (companyId && shopId) {
        const fractionalDiscountType = discountTypes.find(
          (type) => type.type === FractionalDiscountOnSitePaymentDiscountType,
        );

        if (!fractionalDiscountType) {
          throw new Error("fractionalDiscountType is not found");
        }

        try {
          const shopDiscountType: ShopDiscountTypeInsertInput = {
            companyId,
            shopId,
            type: fractionalDiscountType.type,
            onSitePaymentDiscountTypeId: fractionalDiscountType.id,
            isDisplay: Boolean(shop.enableAutoFractionalDiscount),
          };
          const cashRegisterConfig = {
            shopId,
            enableRequiringPasswordForDrawerOpen,
            drawerOpenPassword: drawerOpenPassword
              ? await createSimpleHashWithSHA256(drawerOpenPassword)
              : currentDrawerOpenPassword,
            useStera,
            salesRelatedSlipTaxConfig,
          };

          await Promise.all([
            updateCashRegisterConfigMutation({
              variables: {
                shopId,
                shop,
                shopDiscountType,
                cashRegisterConfig,
              },
            }),
            createSurchargeConfigsMutation({
              variables: {
                input: {
                  shopId,
                  serviceChargeConfig,
                  specificTimeSurchargeConfig,
                },
              },
            }),
          ]);

          refetch();

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [
      companyId,
      shopId,
      discountTypes,
      currentDrawerOpenPassword,
      updateCashRegisterConfigMutation,
      createSurchargeConfigsMutation,
      refetch,
    ],
  );
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <>
          <EditCashRegisterConfigForm
            shop={shop}
            onSubmit={onSubmit}
            onClose={goBack}
            loading={loadingUpdateCashRegisterConfig || loadingCreateSurchargeConfigs}
            onFormValidationError={onFormValidationError}
            refetchShop={refetch}
          />
          <Routes>
            <Route
              path="accountingSlipImage/add/:position"
              element={
                <AddAccountingSlipImageModal
                  shopId={shop.shopId}
                  goBack={goBack}
                  onUpdated={refetch}
                />
              }
            />
            <Route
              path="accountingSlipImage/:accountingSlipImageId/edit"
              element={<EditAccountingSlipImageModal goBack={goBack} onUpdated={refetch} />}
            />
            <Route
              path="accountingSlipImage/preview"
              element={<PreviewAccountingSlipImageModal shopId={shopId} goBack={goBack} />}
            />
          </Routes>
        </>
      )}
    </DashboardLayout>
  );
};
