import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanPikaichiPlanGetPlan = gql`
    query EditPlanPikaichiPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        id
        planChoiceId
        name
        pikaichiMenuPlanChoices {
          pikaichiMenuId
          planChoiceId: _planChoiceId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;
export const EditPlanPikaichiPlanGetPikaichiMenuCd = gql`
    query EditPlanPikaichiPlanGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuCd
    pikaichiMenuId
    pikaichiMenuName
  }
}
    `;
export const EditPlanPikaichiPlanUpdatePikaichiMenu = gql`
    mutation EditPlanPikaichiPlanUpdatePikaichiMenu($planId: uuid!, $_planId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!, $pikaichiMenus: [pikaichiMenu_insert_input!]!, $pikaichiMenuPlanChoices: [pikaichiMenuPlanChoice_insert_input!]!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlan_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planId: $planId, _planId: $_planId}
    on_conflict: {constraint: pikaichiMenuPlan_pikaichiMenuId_planId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planId
    _planId
  }
  insert_pikaichiMenu(
    objects: $pikaichiMenus
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    affected_rows
  }
  insert_pikaichiMenuPlanChoice(
    objects: $pikaichiMenuPlanChoices
    on_conflict: {constraint: pikaichiMenuPlanChoice_pikaichiMenuId_planChoiceId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanPikaichiPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanPikaichiPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, pikaichiMenuPlans: Array<(
      { __typename?: 'pikaichiMenuPlan' }
      & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId'>
      & { planId: Types.PikaichiMenuPlan['_planId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'id' | 'planChoiceId' | 'name'>
        & { pikaichiMenuPlanChoices: Array<(
          { __typename?: 'pikaichiMenuPlanChoice' }
          & Pick<Types.PikaichiMenuPlanChoice, 'pikaichiMenuId'>
          & { planChoiceId: Types.PikaichiMenuPlanChoice['_planChoiceId'] }
          & { pikaichiMenu: (
            { __typename?: 'pikaichiMenu' }
            & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanPikaichiPlanGetPikaichiMenuCdQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenu: Array<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuCd' | 'pikaichiMenuId' | 'pikaichiMenuName'>
  )> }
);

export type EditPlanPikaichiPlanUpdatePikaichiMenuMutationVariables = Types.Exact<{
  planId: Types.Scalars['uuid'];
  _planId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
  pikaichiMenus: Array<Types.PikaichiMenuInsertInput> | Types.PikaichiMenuInsertInput;
  pikaichiMenuPlanChoices: Array<Types.PikaichiMenuPlanChoiceInsertInput> | Types.PikaichiMenuPlanChoiceInsertInput;
}>;


export type EditPlanPikaichiPlanUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuPlan_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlan' }
    & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId' | 'planId' | '_planId'>
  )>, insert_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, insert_pikaichiMenuPlanChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlanChoice_mutation_response' }
    & Pick<Types.PikaichiMenuPlanChoiceMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanPikaichiPlanGetPlanDocument = gql`
    query EditPlanPikaichiPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        id
        planChoiceId
        name
        pikaichiMenuPlanChoices {
          pikaichiMenuId
          planChoiceId: _planChoiceId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEditPlanPikaichiPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanPikaichiPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPikaichiPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanPikaichiPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanPikaichiPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanPikaichiPlanGetPlanQuery, EditPlanPikaichiPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanPikaichiPlanGetPlanQuery, EditPlanPikaichiPlanGetPlanQueryVariables>(EditPlanPikaichiPlanGetPlanDocument, options);
      }
export function useEditPlanPikaichiPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanPikaichiPlanGetPlanQuery, EditPlanPikaichiPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanPikaichiPlanGetPlanQuery, EditPlanPikaichiPlanGetPlanQueryVariables>(EditPlanPikaichiPlanGetPlanDocument, options);
        }
export type EditPlanPikaichiPlanGetPlanQueryHookResult = ReturnType<typeof useEditPlanPikaichiPlanGetPlanQuery>;
export type EditPlanPikaichiPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanPikaichiPlanGetPlanLazyQuery>;
export type EditPlanPikaichiPlanGetPlanQueryResult = Apollo.QueryResult<EditPlanPikaichiPlanGetPlanQuery, EditPlanPikaichiPlanGetPlanQueryVariables>;
export const EditPlanPikaichiPlanGetPikaichiMenuCdDocument = gql`
    query EditPlanPikaichiPlanGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuCd
    pikaichiMenuId
    pikaichiMenuName
  }
}
    `;

/**
 * __useEditPlanPikaichiPlanGetPikaichiMenuCdQuery__
 *
 * To run a query within a React component, call `useEditPlanPikaichiPlanGetPikaichiMenuCdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPikaichiPlanGetPikaichiMenuCdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanPikaichiPlanGetPikaichiMenuCdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanPikaichiPlanGetPikaichiMenuCdQuery(baseOptions: Apollo.QueryHookOptions<EditPlanPikaichiPlanGetPikaichiMenuCdQuery, EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanPikaichiPlanGetPikaichiMenuCdQuery, EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables>(EditPlanPikaichiPlanGetPikaichiMenuCdDocument, options);
      }
export function useEditPlanPikaichiPlanGetPikaichiMenuCdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanPikaichiPlanGetPikaichiMenuCdQuery, EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanPikaichiPlanGetPikaichiMenuCdQuery, EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables>(EditPlanPikaichiPlanGetPikaichiMenuCdDocument, options);
        }
export type EditPlanPikaichiPlanGetPikaichiMenuCdQueryHookResult = ReturnType<typeof useEditPlanPikaichiPlanGetPikaichiMenuCdQuery>;
export type EditPlanPikaichiPlanGetPikaichiMenuCdLazyQueryHookResult = ReturnType<typeof useEditPlanPikaichiPlanGetPikaichiMenuCdLazyQuery>;
export type EditPlanPikaichiPlanGetPikaichiMenuCdQueryResult = Apollo.QueryResult<EditPlanPikaichiPlanGetPikaichiMenuCdQuery, EditPlanPikaichiPlanGetPikaichiMenuCdQueryVariables>;
export const EditPlanPikaichiPlanUpdatePikaichiMenuDocument = gql`
    mutation EditPlanPikaichiPlanUpdatePikaichiMenu($planId: uuid!, $_planId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!, $pikaichiMenus: [pikaichiMenu_insert_input!]!, $pikaichiMenuPlanChoices: [pikaichiMenuPlanChoice_insert_input!]!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlan_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planId: $planId, _planId: $_planId}
    on_conflict: {constraint: pikaichiMenuPlan_pikaichiMenuId_planId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planId
    _planId
  }
  insert_pikaichiMenu(
    objects: $pikaichiMenus
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    affected_rows
  }
  insert_pikaichiMenuPlanChoice(
    objects: $pikaichiMenuPlanChoices
    on_conflict: {constraint: pikaichiMenuPlanChoice_pikaichiMenuId_planChoiceId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanPikaichiPlanUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<EditPlanPikaichiPlanUpdatePikaichiMenuMutation, EditPlanPikaichiPlanUpdatePikaichiMenuMutationVariables>;

/**
 * __useEditPlanPikaichiPlanUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `useEditPlanPikaichiPlanUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPikaichiPlanUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanPikaichiPlanUpdatePikaichiMenuMutation, { data, loading, error }] = useEditPlanPikaichiPlanUpdatePikaichiMenuMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      _planId: // value for '_planId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *      pikaichiMenus: // value for 'pikaichiMenus'
 *      pikaichiMenuPlanChoices: // value for 'pikaichiMenuPlanChoices'
 *   },
 * });
 */
export function useEditPlanPikaichiPlanUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanPikaichiPlanUpdatePikaichiMenuMutation, EditPlanPikaichiPlanUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanPikaichiPlanUpdatePikaichiMenuMutation, EditPlanPikaichiPlanUpdatePikaichiMenuMutationVariables>(EditPlanPikaichiPlanUpdatePikaichiMenuDocument, options);
      }
export type EditPlanPikaichiPlanUpdatePikaichiMenuMutationHookResult = ReturnType<typeof useEditPlanPikaichiPlanUpdatePikaichiMenuMutation>;
export type EditPlanPikaichiPlanUpdatePikaichiMenuMutationResult = Apollo.MutationResult<EditPlanPikaichiPlanUpdatePikaichiMenuMutation>;
export type EditPlanPikaichiPlanUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<EditPlanPikaichiPlanUpdatePikaichiMenuMutation, EditPlanPikaichiPlanUpdatePikaichiMenuMutationVariables>;