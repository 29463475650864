import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPikaichiPlanGetPlan = gql`
    query EditPikaichiPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const EditPikaichiPlanGetPikaichiMenus = gql`
    query EditPikaichiPlanGetPikaichiMenus($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuId
    pikaichiMenuName
    pikaichiMenuCd
  }
}
    `;
export const EditPikaichiPlanUpdatePikaichiMenu = gql`
    mutation EditPikaichiPlanUpdatePikaichiMenu($planId: uuid!, $_planId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlan_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planId: $planId, _planId: $_planId}
    on_conflict: {constraint: pikaichiMenuPlan_pikaichiMenuId_planId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planId
    _planId
  }
}
    `;
export type EditPikaichiPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPikaichiPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, pikaichiMenuPlans: Array<(
      { __typename?: 'pikaichiMenuPlan' }
      & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId'>
      & { planId: Types.PikaichiMenuPlan['_planId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type EditPikaichiPlanGetPikaichiMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPikaichiPlanGetPikaichiMenusQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenu: Array<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuName' | 'pikaichiMenuCd'>
  )> }
);

export type EditPikaichiPlanUpdatePikaichiMenuMutationVariables = Types.Exact<{
  planId: Types.Scalars['uuid'];
  _planId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
}>;


export type EditPikaichiPlanUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuPlan_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlan' }
    & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId' | 'planId' | '_planId'>
  )> }
);


export const EditPikaichiPlanGetPlanDocument = gql`
    query EditPikaichiPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __useEditPikaichiPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPikaichiPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPikaichiPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiPlanGetPlanQuery, EditPikaichiPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiPlanGetPlanQuery, EditPikaichiPlanGetPlanQueryVariables>(EditPikaichiPlanGetPlanDocument, options);
      }
export function useEditPikaichiPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiPlanGetPlanQuery, EditPikaichiPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiPlanGetPlanQuery, EditPikaichiPlanGetPlanQueryVariables>(EditPikaichiPlanGetPlanDocument, options);
        }
export type EditPikaichiPlanGetPlanQueryHookResult = ReturnType<typeof useEditPikaichiPlanGetPlanQuery>;
export type EditPikaichiPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditPikaichiPlanGetPlanLazyQuery>;
export type EditPikaichiPlanGetPlanQueryResult = Apollo.QueryResult<EditPikaichiPlanGetPlanQuery, EditPikaichiPlanGetPlanQueryVariables>;
export const EditPikaichiPlanGetPikaichiMenusDocument = gql`
    query EditPikaichiPlanGetPikaichiMenus($companyId: uuid!) {
  pikaichiMenu(
    where: {companyId: {_eq: $companyId}, _or: [{pikaichiMenuMenus: {menu: {archivedAt: {_is_null: true}}}}, {pikaichiMenuChoices: {choice: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlans: {plan: {archivedAt: {_is_null: true}}}}, {pikaichiMenuPlanChoices: {planChoice: {archivedAt: {_is_null: true}}}}]}
  ) {
    pikaichiMenuId
    pikaichiMenuName
    pikaichiMenuCd
  }
}
    `;

/**
 * __useEditPikaichiPlanGetPikaichiMenusQuery__
 *
 * To run a query within a React component, call `useEditPikaichiPlanGetPikaichiMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiPlanGetPikaichiMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiPlanGetPikaichiMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPikaichiPlanGetPikaichiMenusQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiPlanGetPikaichiMenusQuery, EditPikaichiPlanGetPikaichiMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiPlanGetPikaichiMenusQuery, EditPikaichiPlanGetPikaichiMenusQueryVariables>(EditPikaichiPlanGetPikaichiMenusDocument, options);
      }
export function useEditPikaichiPlanGetPikaichiMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiPlanGetPikaichiMenusQuery, EditPikaichiPlanGetPikaichiMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiPlanGetPikaichiMenusQuery, EditPikaichiPlanGetPikaichiMenusQueryVariables>(EditPikaichiPlanGetPikaichiMenusDocument, options);
        }
export type EditPikaichiPlanGetPikaichiMenusQueryHookResult = ReturnType<typeof useEditPikaichiPlanGetPikaichiMenusQuery>;
export type EditPikaichiPlanGetPikaichiMenusLazyQueryHookResult = ReturnType<typeof useEditPikaichiPlanGetPikaichiMenusLazyQuery>;
export type EditPikaichiPlanGetPikaichiMenusQueryResult = Apollo.QueryResult<EditPikaichiPlanGetPikaichiMenusQuery, EditPikaichiPlanGetPikaichiMenusQueryVariables>;
export const EditPikaichiPlanUpdatePikaichiMenuDocument = gql`
    mutation EditPikaichiPlanUpdatePikaichiMenu($planId: uuid!, $_planId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlan_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planId: $planId, _planId: $_planId}
    on_conflict: {constraint: pikaichiMenuPlan_pikaichiMenuId_planId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planId
    _planId
  }
}
    `;
export type EditPikaichiPlanUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<EditPikaichiPlanUpdatePikaichiMenuMutation, EditPikaichiPlanUpdatePikaichiMenuMutationVariables>;

/**
 * __useEditPikaichiPlanUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `useEditPikaichiPlanUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiPlanUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPikaichiPlanUpdatePikaichiMenuMutation, { data, loading, error }] = useEditPikaichiPlanUpdatePikaichiMenuMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      _planId: // value for '_planId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *   },
 * });
 */
export function useEditPikaichiPlanUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditPikaichiPlanUpdatePikaichiMenuMutation, EditPikaichiPlanUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPikaichiPlanUpdatePikaichiMenuMutation, EditPikaichiPlanUpdatePikaichiMenuMutationVariables>(EditPikaichiPlanUpdatePikaichiMenuDocument, options);
      }
export type EditPikaichiPlanUpdatePikaichiMenuMutationHookResult = ReturnType<typeof useEditPikaichiPlanUpdatePikaichiMenuMutation>;
export type EditPikaichiPlanUpdatePikaichiMenuMutationResult = Apollo.MutationResult<EditPikaichiPlanUpdatePikaichiMenuMutation>;
export type EditPikaichiPlanUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<EditPikaichiPlanUpdatePikaichiMenuMutation, EditPikaichiPlanUpdatePikaichiMenuMutationVariables>;