import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { WinboardMenuHeader } from "components/PageHeader/WinboardMenuHeader";
import { useCompany } from "hooks/useCompany";
import { WinboardMenuInsertInput } from "types/graphql";

import { EditWinboardMenuForm } from "./EditWinboardMenuForm";
import {
  useEditWinboardMenuGetMenuQuery,
  useEditWinboardMenuGetWinboardMenusQuery,
  useEditWinboardMenuUpdateWinboardMenuMutation,
} from "./queries";

export const EditWinboardMenu = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
    error,
  } = useEditWinboardMenuGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];

  const winboardMenu = menu?.winboardMenus[0];

  const { data: getWinboardMenusData } = useEditWinboardMenuGetWinboardMenusQuery(
    menu?.companyId ? { variables: { companyId: menu?.companyId } } : { skip: true },
  );
  const winboardMenus = useMemo(
    () => getWinboardMenusData?.winboardMenu ?? [],
    [getWinboardMenusData],
  );

  setCompany(menu?.companyId);

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useEditWinboardMenuUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardMenuInsertInput, "companyId">) => {
      if (!company || !menu) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto = {
        id,
        menuId: menu.id,
        _menuId: menu.menuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updateWinboardMenuMutation({
          variables: { winboardMenu: winboardMenuDto },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchMenu();
    },
    [company, menu, winboardMenu?.id, refetchMenu, updateWinboardMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ウィンボードメニュー" }],
      }}
    >
      <WinboardMenuHeader menu={menu ?? null} onBack={goBack} />
      {loadingMenu && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menu && (
        <EditWinboardMenuForm
          menu={menu}
          winboardMenus={winboardMenus}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </DashboardLayout>
  );
};
