import React, { memo } from "react";
import styled from "styled-components";
import { Radio } from "antd";

import { FormItemProps } from "components/antd/Form";
import { TaxMethodEnum } from "types/graphql";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const options = [
  { label: "税込", value: TaxMethodEnum.Included },
  { label: "税抜", value: TaxMethodEnum.Excluded },
];

type Props = Omit<FormItemProps, "children" | "name">;

export const SalesRelatedSlipTaxConfigField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label="点検・精算伝票の売上詳細情報の税表示"
    name="salesRelatedSlipTaxConfig"
    {...props}
  >
    <RadioGroup options={options} optionType="default" />
  </EditCashRegisterConfigFormItem>
));
