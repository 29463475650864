import React, { memo, useCallback } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiMenuInsertInput } from "types/graphql";

import { Menu, PikaichiMenu } from "../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiGetAvailableMenuCdButtonField } from "./PikaichiGetAvailableMenuCdButtonField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { useEditMenuPikaichiMenuForm } from "./useEditMenuPikaichiMenuForm";

type Props = {
  menu: Menu;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => string;
  loading: boolean;
};

export const EditMenuPikaichiMenuForm = memo<Props>(
  ({
    menu,
    pikaichiMenus,
    onClose,
    onSubmit,
    onGetAvailableMenuCodeButtonPressed: onGetAvailableMenuCodeButtonPressed,
    loading,
  }) => {
    const pikaichiMenu = menu?.pikaichiMenuMenus?.[0]?.pikaichiMenu;
    const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId;

    const { form, initialValues, submit } = useEditMenuPikaichiMenuForm({
      menu,
      pikaichiMenu,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();
      form.setFields([{ name: "pikaichiMenuCd", value: maxMenuCode }]);
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form name="pikaichiMenu" form={form} initialValues={initialValues} layout="vertical">
            <Row gutter={24}>
              <Col flex={1}>
                <PikaichiMenuCdField
                  pikaichiMenus={pikaichiMenus}
                  pikaichiMenuId={pikaichiMenuId}
                />
              </Col>
              <Col>
                <PikaichiGetAvailableMenuCdButtonField
                  handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
                />
              </Col>
            </Row>
            <PikaichiMenuNameField />
            <PikaichiBumonCdField />
            <PikaichiBumonNameField />
            <PikaichiCategoryCdField />
            <PikaichiCategoryNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
