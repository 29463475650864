import React, { memo, useCallback } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList } from "components/antd/Form";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { PikaichiMenu } from "../../types";
import { EditPlanPikaichiPlanFormItem } from "../useEditPlanPikaichiPlanForm";

import { PikaichiGetAvailableMenuCdForOptionButtonField } from "./PikaichiGetAvailableMenuCdForOptionButtonField";
import { PikaichiPlanChoiceTable } from "./PikaichiPlanChoiceTable";

type Props = {
  loading?: boolean;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  handleGetAvailableMenuCodeForOptionButtonPressed: () => void;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const PikaichiPlanOptionTable = memo<Props>(
  ({ loading, pikaichiMenus, handleGetAvailableMenuCodeForOptionButtonPressed }) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<FormListFieldDataSource> = [
      {
        title: "オプション名",
        key: "name",
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const optionName = getFieldValue(["plan", "planOptions", field.name, "name"]);
                return <span>{optionName}</span>;
              }}
            </EditPlanPikaichiPlanFormItem.NonProperty>
          );
        },
      },
    ];

    const expandedRowRender = useCallback(
      (optionField: FormListFieldData) => (
        <PikaichiPlanChoiceTable optionField={optionField} pikaichiMenus={pikaichiMenus} />
      ),
      [pikaichiMenus],
    );

    return (
      <FormList name={["plan", "planOptions"]}>
        {(planOptionFields) => (
          <Table
            rowKey="key"
            columns={columns}
            dataSource={planOptionFields}
            loading={loading}
            bordered
            expandable={{ expandedRowRender, defaultExpandAllRows: true }}
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            shouldDisableMaxWidthScroll
            footer={() => (
              <PikaichiGetAvailableMenuCdForOptionButtonField
                handleGetAvailableMenuCodeButtonPressed={
                  handleGetAvailableMenuCodeForOptionButtonPressed
                }
              />
            )}
          />
        )}
      </FormList>
    );
  },
);
