import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiMenuInsertInput } from "types/graphql";

import { Menu, PikaichiMenu } from "../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { useEditPikaichiMenuForm } from "./useEditPikaichiMenuForm";

type Props = {
  menu: Menu;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPikaichiMenuForm = memo<Props>(
  ({ menu, pikaichiMenus, onClose, onSubmit, loading }) => {
    const pikaichiMenu = menu?.pikaichiMenuMenus?.[0]?.pikaichiMenu;
    const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId;

    const { form, initialValues, submit } = useEditPikaichiMenuForm({
      menu,
      pikaichiMenu,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form name="pikaichiMenu" form={form} initialValues={initialValues} layout="vertical">
            <PikaichiMenuCdField pikaichiMenus={pikaichiMenus} pikaichiMenuId={pikaichiMenuId} />
            <PikaichiMenuNameField />
            <PikaichiBumonCdField />
            <PikaichiBumonNameField />
            <PikaichiCategoryCdField />
            <PikaichiCategoryNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
