import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { Choice, PikaichiMenu, PikaichiMenuChoices } from "../../types";
import { EditOptionPikaichiOptionFormItem } from "../useEditOptionPikaichiOptionForm";

type Props = {
  loading?: boolean;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

type rowItem = Pick<Choice, "name" | "choiceId"> & {
  pikaichiMenuChoices: Array<
    Pick<PikaichiMenuChoices, "pikaichiMenuId" | "choiceId"> & {
      pikaichiMenu: Pick<
        PikaichiMenu,
        | "pikaichiMenuId"
        | "pikaichiMenuCd"
        | "pikaichiMenuName"
        | "pikaichiBumonCd"
        | "pikaichiBumonName"
        | "pikaichiCategoryCd"
        | "pikaichiCategoryName"
      >;
    }
  >;
};

export const PikaichiOptionChoiceTable = memo<Props>(({ loading, pikaichiMenus }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ option }) => [
              option?.choices.map((choice) => choice.name),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue(["option", "choices", field.name, "name"]);

              return <span>{choiceName}</span>;
            }}
          </EditOptionPikaichiOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "pikaichiMenuCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem.NonProperty noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <EditOptionPikaichiOptionFormItem
                name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiMenuCd"]}
                rules={[
                  {
                    required: true,
                    pattern: /^[1-9]\d{0,7}$/,
                    message: "8桁以内の数字で入力してください",
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) return;

                      const choices: rowItem[] = getFieldValue(["option", "choices"]) || [];
                      const currentChoice = choices[field.name];

                      const conflictingExistingPikaichiMenu = pikaichiMenus.find(
                        ({ pikaichiMenuId, pikaichiMenuCd }) =>
                          pikaichiMenuCd === value &&
                          pikaichiMenuId !==
                            currentChoice?.pikaichiMenuChoices[0]?.pikaichiMenu?.pikaichiMenuId,
                      );

                      if (conflictingExistingPikaichiMenu) {
                        const editingPikaichiMenu = choices.find(
                          (choice) =>
                            choice.pikaichiMenuChoices[0]?.pikaichiMenu?.pikaichiMenuCd !== value &&
                            choice.pikaichiMenuChoices[0]?.pikaichiMenu?.pikaichiMenuId ===
                              conflictingExistingPikaichiMenu?.pikaichiMenuId,
                        );

                        if (!editingPikaichiMenu) {
                          throw new Error(
                            `既存のコードと競合しています ${conflictingExistingPikaichiMenu.pikaichiMenuName}`,
                          );
                        }
                      }

                      const conflictingEditingPikaichiMenu = choices.filter(
                        (choice) =>
                          choice.pikaichiMenuChoices[0]?.pikaichiMenu?.pikaichiMenuCd === value &&
                          choice?.choiceId !== currentChoice?.choiceId,
                      );

                      if (conflictingEditingPikaichiMenu[0]) {
                        throw new Error(
                          `既存のコードと競合しています ${conflictingEditingPikaichiMenu[0].pikaichiMenuChoices[0]?.pikaichiMenu?.pikaichiMenuName}`,
                        );
                      }
                    },
                  },
                ]}
                endSpacer={null}
              >
                <InputCode />
              </EditOptionPikaichiOptionFormItem>
            )}
          </EditOptionPikaichiOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニュー名",
      key: "pikaichiMenuName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiMenuName"]}
            rules={[{ required: true, max: 25, message: "25文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "pikaichiBumonCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiBumonCd"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "pikaichiBumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiBumonName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "pikaichiCategoryCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiCategoryCd"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "pikaichiCategoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiCategoryName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={["option", "choices"]}>
      {(optionChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={optionChoiceFields}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
