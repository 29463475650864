import React, { memo, useCallback } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { WinboardMenuSetInput } from "types/graphql";

import { Menu, WinboardMenu } from "../types";

import { useEditMenuWinboardMenuForm } from "./useEditMenuWinboardMenuForm";
import { WinboardBumonCodeField } from "./WinboardBumonCodeField";
import { WinboardBumonNameField } from "./WinboardBumonNameField";
import { WinboardCategoryCodeField } from "./WinboardCategoryCodeField";
import { WinboardCategoryNameField } from "./WinboardCategoryNameField";
import { WinboardCodeField } from "./WinboardCodeField";
import { WinboardGetAvailableMenuCodeButtonField } from "./WinboardGetAvailableMenuCodeButtonField";
import { WinboardNameField } from "./WinboardNameField";

type Props = {
  menu: Menu;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code">[];
  onSubmit: (args: Omit<WinboardMenuSetInput, "companyId" | "_companyId">) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => string;
  loading: boolean;
};

export const EditMenuWinboardMenuForm = memo<Props>(
  ({ menu, winboardMenus, onClose, onSubmit, onGetAvailableMenuCodeButtonPressed, loading }) => {
    const winboardMenu = menu?.winboardMenus?.[0] ?? undefined;
    const winboardMenuId = winboardMenu?.id;

    const { form, initialValues, submit } = useEditMenuWinboardMenuForm({
      menu,
      winboardMenu,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();
      form.setFields([{ name: "code", value: maxMenuCode }]);
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form name="winboardMenu" form={form} initialValues={initialValues} layout="vertical">
            <Row gutter={24}>
              <Col flex={1}>
                <WinboardCodeField winboardMenus={winboardMenus} winboardMenuId={winboardMenuId} />
              </Col>
              <Col>
                <WinboardGetAvailableMenuCodeButtonField
                  handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
                />
              </Col>
            </Row>
            <WinboardNameField />
            <WinboardBumonCodeField />
            <WinboardBumonNameField />
            <WinboardCategoryCodeField />
            <WinboardCategoryNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
